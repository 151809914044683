<template>
  <div id="publication-list">
    <!-- list filters -->
    <v-card>
      <v-card-title>
        {{ $t("Reporting") }}<v-chip outlined class="ml-2">{{ totalItems }}</v-chip>
        <v-spacer />
        <export-excel
          :options="downloadObject"
          @refetch-data="() => fetchItems(true, 'reporting')"
        />
      </v-card-title>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="filters"
        :items="items"
        class="fixed-table"
        :options.sync="options"
        :page.sync="page"
        :server-items-length="totalItems"
        :loading="loading"
        :loading-text="$t('Loading data')"
        :no-data-text="$t('No data')"
        :no-results-text="$t('No result')"
        hide-default-footer
        :show-select="false"
        dense fixed-header height="500"
        @page-count="pageCount = $event"
      >
        <template #body.prepend="{ items, headers }">
          <header-filters :headers="filters" />
        </template>
        <template #item="options">
          <Row :options="options" :config="{ url: '/publications/action/', author: 'author', manuscript: 'manuscript', resource: 'Report' }"
               >
        <!-- name -->
        <template #[`item.author.full_name`]="{item}">
          <div class="d-flex align-center" v-if="item.author && item.author.id">
            <v-avatar
              :color="item.author.avatar ? '' : 'primary'"
              :class="item.author.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="30"
            >
              <v-img
                v-if="item.author.avatar"
                :lazy-src="require(`@/assets/images/avatars/2.png`)"
                :src="item.author.avatar || require(`@/assets/images/avatars/2.png`)"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(item.author.full_name) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'author-view', params : { id : item.author_id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.author.full_name }}
              </router-link>
              <!--              <small>@{{ item.publicationname }}</small>-->
            </div>
          </div>
        </template>
        <template #[`item.author.old_author`]="{item}">
          <v-icon
            v-if="item.author && item.author.old_author"
            small
            color="success"
          >
            {{ icons.mdiCheckCircle }}
          </v-icon>
        </template>
        <template #[`item.author.published`]="{item}">
          <div class="text-center">
            <v-icon
              v-if="item.published"
              small
              color="success"
            >
              {{ icons.mdiCheckCircle }}
            </v-icon>
          </div>
        </template>
            <template #[`item.is_text_cover_finished`]="{item}">
              <div class="text-center">
                <v-icon
                  v-if="item.is_text_cover_finished"
                  small
                  color="success"
                >
                  {{ icons.mdiCheckCircle }}
                </v-icon>
              </div>
            </template>
        <!-- pay -->
        <template #[`item.country`]="{item}">
          <div
            v-if="item.country"
            class="d-flex align-center"
          >
            <span class="text-capitalize">{{ item.country.name }}</span>
          </div>
        </template>
        <template #[`item.is_reading1`]="{item}">
          <span>
            <v-icon  v-if="item.is_reading" small color="success">
            {{ icons.mdiCheckCircle }}
          </v-icon>
          </span>
        </template>
        <template #[`item.completion_date`]="{item}">
          <span v-if="item.completion_date && !item.completion_date.includes('0000')">{{ formatDate(item.completion_date || null, { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span>
        </template>
        <!-- status -->


        <!--   /Resiliatioin     -->

        <template #[`item.status`]="{item}">
          <v-chip
            v-if="item.status && item.status.id"
            small
            :color="item.status.color"
            :class="`${item.status.color}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.status.name }}
          </v-chip>
          <!--          <manuscript-status-chip
            :object="item"
            @refetch-data="fetchManuscripts"
          />-->
        </template>
          </Row>
        </template>
      </v-data-table>
      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <rows-per-page :options="options" />
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="page"
              total-visible="6"
              :length="pageCount"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="deleteDialog"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 300 : '100%'"
    >
      <v-card>
        <v-card-title>{{ $t("Confirm delete") }}</v-card-title>
        <v-divider />
        <v-card-text>{{ $t("Are you sure to delete?") }}</v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            small
            outlined
            @click="deleteDialog = false"
          >
            {{ $t("No") }}
          </v-btn>
          <v-spacer />
          <v-btn
            small
            color="primary"
            :loading="loading"
            @click="deleteItem"
          >
            {{ $t("Yes") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"
      :light="$vuetify.theme.dark"
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiClose,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
  mdiPencilOutline,
  mdiCheckCircle,
  mdiCheckboxMarkedCircleOutline,
  mdiCheckboxMarkedCircle,
} from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'

// sidebar
import { avatarText, formatDate } from '@core/utils/filter'
import axios from '@axios'
import store from '@/store'
import itemStoreModule from './itemStoreModule'

import controller from './Controller'
import RowsPerPage from '@/components/RowsPerPage'
import HeaderFilters from '@/components/HeaderFilters'
import ability from '@/plugins/acl/ability'
import ExportExcel from '@/components/ExportExcel'
import TableRow from '@/components/TableRow'
import Row from "@/components/Row";

export default {
  components: {
    Row,
    TableRow,
    ExportExcel,
    HeaderFilters,
    RowsPerPage,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-publication'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, itemStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      stateFilter,
      publicationStates,
      item,
      items,
      tabFilter,
      downloadObject,
      tableCommonColumns,
      signatureTableColumnsForReport,
      textTableColumnsForReport,
      collectTableColumnsForReport,
      coverTableColumnsForReport,
      supervisionTableColumnsForReport,
      publicationTableColumnsForReport,
      paymentTableColumnsForReport,
      resiliationTableColumnsForReport,
      filters,
      searchQuery,
      payFilter,
      sourceFilter,
      statusFilter,
      totalItems,
      loading,
      options,
      publicationTotalLocal,
      selectedRows,
      isFormActive,
      snackBarMessage,
      isSnackbarBottomVisible,
      openDialog,
      deleteItem,
      deleteDialog,
      confirmDelete,
      fetchItems,
      resolvePublicationRoleVariant,
      resolvePublicationRoleIcon,
      resolvePublicationStatusVariant,
      resolvePublicationStatusName,
      resolvePublicationTotalIcon,
    } = controller()

    const assistants = ref([])
    const assistantsPub = ref([])
    const superiors = ref([])
    const payOptions = [
      { title: 'Admin', value: 'admin' },
      { title: 'Publication', value: 'publication' },
      { title: 'Editor', value: 'editor' },
      { title: 'Maintainer', value: 'maintainer' },
      { title: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { title: 'Basic', value: 'basic' },
      { title: 'Company', value: 'company' },
      { title: 'Enterprise', value: 'enterprise' },
      { title: 'Standard', value: 'standard' },
    ]

    const statusOptions = [
      { title: 'Active', value: 1 },
      { title: 'Inactive', value: 0 },
    ]

    tabFilter.value = 'text'
    store
      .dispatch('app-publication/fetchCountries', { per_page: 500 })
    store
      .dispatch('app-publication/fetchSources', { per_page: 500 })
    store
      .dispatch('app/fetchGenres', { per_page: 500 })
    store
      .dispatch('app/fetchFormats', { per_page: 500 })
    axios
      .get('/statuses', { params: { per_page: 500, type: 'publication' } })
      .then(response => {
        const indexH = filters.value.findIndex(ele => ele.value === 'status')
        if (indexH > 0) {
          filters.value[indexH].filter.values = response.data.data
        }
      }).catch(error => console.log(error))

    if (ability.can('assistant', 'Cover')) {
      axios
        .get('/listWorkers', {
          params: {
            term: '', type: 'text_cover', user: JSON.parse(localStorage.getItem('userData')).id || null, rowsPerPage: 100,
          },
        })
        .then(response => {
          for (let i = 0; i < response.data.length; i++) {
            const u = response.data[i]
            const indexU = assistants.value.findIndex(el => el.id === u.id)
            if (indexU < 0) {
              assistants.value.push(u)
            }
          }
          const indexH = filters.value.findIndex(ele => ele.value === 'assistant.code')
          if (indexH > 0) {
            filters.value[indexH].filter.values = assistants.value
          }
        })
    }
    if (ability.can('assistant', 'Publication')) {
      axios
        .get('/listWorkers', {
          params: {
            term: '', type: 'publication', user: JSON.parse(localStorage.getItem('userData')).id || null, rowsPerPage: 100,
          },
        })
        .then(response => {
          for (let i = 0; i < response.data.length; i++) {
            const u = response.data[i]
            const indexU = assistantsPub.value.findIndex(el => el.id === u.id)
            if (indexU < 0) {
              assistantsPub.value.push(u)
            }
          }
          const indexH = filters.value.findIndex(ele => ele.value === 'assistant_publication.code')
          if (indexH > 0) {
            filters.value[indexH].filter.values = assistantsPub.value
          }
        })
    }
    // stateFilter.value = [publicationStates.value.CREATED_STATE, publicationStates.value.SEND_STATE, publicationStates.value.RESEND_STATE]

    filters.value = [
      ...tableCommonColumns,
      ...signatureTableColumnsForReport,
      ...collectTableColumnsForReport,
      ...textTableColumnsForReport,
      ...coverTableColumnsForReport,
      ...supervisionTableColumnsForReport,
      ...publicationTableColumnsForReport,
      ...paymentTableColumnsForReport,
      ...resiliationTableColumnsForReport,
    ]/* .filter((ele) => !ele.action || ability.can(ele.action, 'Manage')) */

    const formatDateShort = (date, option) => formatDate(date, option)

    return {
      item,
      items,
      downloadObject,
      superiors,
      filters,
      searchQuery,
      payFilter,
      sourceFilter,
      formatDateShort,
      statusFilter,
      totalItems,
      loading,
      options,
      publicationTotalLocal,
      isFormActive,
      selectedRows,
      snackBarMessage,
      isSnackbarBottomVisible,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      openDialog,
      deleteItem,
      deleteDialog,
      confirmDelete,
      avatarText,
      formatDate,
      resolvePublicationRoleVariant,
      resolvePublicationRoleIcon,
      resolvePublicationStatusVariant,
      resolvePublicationStatusName,
      resolvePublicationTotalIcon,
      fetchItems,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiClose,
        mdiExportVariant,
        mdiAccountOutline,
        mdiPencilOutline,
        mdiCheckCircle,
        mdiCheckboxMarkedCircleOutline,
        mdiCheckboxMarkedCircle,
      },
    }
  },
}
</script>
<style lang="scss">
  /*table {

    background-color: white;
    margin: auto;
    border-collapse: separate;
    border-spacing: 0;
    display: block;
    overflow-x: scroll;
  }
  .v-data-table td, th {
    margin: 0;
    border: 1px solid grey;
    white-space: nowrap;
    border-top-width: 0px;
  }

  .v-data-table thead tr:first-child
  }
  .fixed-column {
    position: absolute;
    width: 5em;
    left: 0;
    top: auto;
    border-top-width: 1px;
    margin-top: -1px;
  }*/
</style>
<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
